
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, PropSync} from 'vue-property-decorator';
import {actionsTypes} from '@/modules/admin/shared/state';
import {httpClient} from '@/shared/services';
import {objectToQueryString} from '@/shared/helpers';
import {AxiosResponse} from 'axios';
import {ModuleFetchPayload} from '@/shared/state/template/module-payloads';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {ErrorHandler} from '@/shared/state/template/helpers';

@Component
export default class InfiniteScroll extends Vue {
    @Prop(String) public fetchAction!: string;
    @Prop({
        type: Object,
        default: () => {
            return { filters: {} };
        },
    })
    public fetchPayload!: { [key: string]: any };
    @Prop(String) public url!: string;
    @Prop(String) public payloadParamsKey!: string;
    @Prop({ type: Number, default: 2 }) public startAtPage!: number;
    @PropSync('meta', { type: Object, default: null }) public metaSync!: any;
    @Prop({ type: Boolean, default: false }) public stop!: boolean;
    /**
     * Use these as Intersection observer options
     */
    @Prop({ type: Element }) public root!: Element;
    @Prop({ type: String, default: '400px' }) public rootMargin!: string;
    @Prop({ type: [Array, Number] }) public treshold!: Element;
    /*
     *  more info at:
     *  https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#intersection_observer_options
     */

    public currentPage!: number;
    public lastPage!: number;
    public loaderKey: number = 0;
    public fetchTimeout: number | null = null;
    public busy = false;
    public lastFetchResults: any[] | null = null;
    public emptyData = false;

    get hasItemsToFetch() {
        if (this.stop || this.emptyData) {
            return false;
        } else if (this.lastFetchResults && this.lastFetchResults.length === 0) {
            return false;
        } else if (this.metaSync) {
            if (this.metaSync.current_page >= this.metaSync.last_page) {
                return false;
            }
        }
        return true;
    }

    get options() {
        return {
            root: this.root || null,
            rootMargin: this.rootMargin || null,
            treshold: this.treshold || null,
        };
    }

    public created() {
        this.currentPage = this.startAtPage - 1;
        this.lastPage = this.currentPage - 1;
    }

    public updated() {
        this.syncCurrentPage();
    }

    // tslint:disable-next-line:variable-name
    public onIntersect(_entries: any, _observer: any, isIntersecting: boolean) {
        if (isIntersecting) {
            this.fetch();
        }
    }

    // public updated() {
    // tslint:disable-next-line
    //     console.log(`stop: ${this.stop}\nemptyData: ${this.emptyData} \nmeta-curr: ${this.meta.current_page}\nmeta-last: ${this.meta.last_page} \nmeta-if: ${this.meta.current_page === this.meta.last_page}\nmeta-total: ${this.meta.total}`);
    // }

    public fetch() {
        if (this.fetchTimeout) {
            window.clearTimeout(this.fetchTimeout);
        }
        if (!this.hasItemsToFetch) {
            return;
        }
        if (this.busy) {
            this.fetchTimeout = window.setTimeout(this.fetch, 100);
            return;
        }

        this.busy = true;
        const payload = this.setPayload();
        (this.url
                ? httpClient.get(`${this.url}?${objectToQueryString(payload)}`).then(
                    (response: AxiosResponse<ModuleFetchPayload<any>>) => response.data
                )
                : this.$store.dispatch(this.fetchAction, {...payload})
        )
            .then((response: ModuleFetchPayload<any>) => {
                if (!response.data || response.data.length === 0) {
                    this.emptyData = true;
                }

                this.$emit('fetchSuccess', response);
                this.busy = false;

                if (this.url) {
                    if (response.data && response.meta) {
                        this.currentPage = response.meta.current_page;
                        this.metaSync = response.meta;
                    }
                    this.lastFetchResults = response.data || null;
                } else {
                    if (response.meta) {
                        this.currentPage = response.meta.current_page;
                    }
                    this.lastFetchResults = response.data || null;
                }
            })
            .catch((err: ErrorPayload) => {
                this.$emit('fetchError', err);
                ErrorHandler(err, '', {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
            })
            .finally(() => {
                this.$emit('fetchDone');
                this.loaderKey += this.currentPage;
            });
    }

    /** Making shure that this.currentPage is in sync with provided meta.
     *  Desync may occour when dispatching provided fetchAction elsewhere
     */
    private syncCurrentPage() {
        if (
            this.currentPage &&
            this.metaSync &&
            this.metaSync.current_page &&
            this.metaSync.current_page !== this.currentPage
        ) {
            this.currentPage = this.metaSync.current_page;
        }
    }

    private setPayload() {
        const nextPage = this.currentPage + 1;
        const payload = this._.cloneDeep(this.fetchPayload);

        if (this.payloadParamsKey) {
            payload[this.payloadParamsKey].page = nextPage;
            payload[this.payloadParamsKey].is_extra_fetch = true;
        } else {
            payload.is_extra_fetch = true;
            payload.page = nextPage;
        }

        return payload;
    }
}
