
import { PickerData, SaveResponse } from '@/shared/types';
import { Component, Watch, Vue, Prop, VModel, Inject, Ref } from 'vue-property-decorator';
import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleState } from '@/shared/state/template/module-state';
import InfiniteScroll from './infinite-scroll.vue';
import { logger } from '@/shared/services';
import { FormRules } from '@/shared/validation/form-rules';
import { uniqueId } from '@/shared/helpers';

@Component({
    components: {
        InfiniteScroll,
    },
})
export default class AutoCompleteWithAddNew extends Vue {
    @Inject() public readonly permissionCheck!: (...args: string[]) => boolean;

    // V-MODEL setup
    @VModel({ type: undefined }) public itemValue!: any;
    @Prop({ type: String, default: 'id' }) public returnValueKey!: string;
    @Prop({ type: Boolean, default: false }) public returnObject!: boolean;

    // VUEX setup
    @Prop(Object) public state!: ModuleState<any, any>;
    @Prop(Object) public module!: ModuleCreator<any, any>;

    // FORM setup
    @Prop(Function) public formComponent!: Vue;
    @Prop({ type: Object, default: () => new Object({}) }) public itemTemplate!: any;
    @Prop({ type: Boolean, default: false }) public noCreateForm!: boolean;

    // V-AUTOCOMPLETE PROPS
    @Prop(String) public label!: string;
    @Prop(Boolean) public disabled!: boolean;
    @Prop(Boolean) public dense!: boolean;
    @Prop(Boolean) public readOnly!: boolean;
    @Prop(Object) public filters!: any;
    @Prop(Array) public rules!: FormRules;
    @Prop(String) public error!: string;
    @Prop(Array) public errorMessages!: any[];
    @Prop({ type: Boolean, default: true }) public clearable!: boolean;
    @Prop([String, Function, Array]) public itemDisabled!: any;
    @Prop(Boolean) public validateOnBlur!: boolean;
    @Prop(String) public placeholder!: boolean;

    @Prop(String) public parentName!: string;

    @Ref() public createItemBtn!: Vue;

    public picker!: PickerData;

    get permissionNames() {
        return this.module.moduleProps.permissionBaseNames.map((permission: string) => permission + '.create');
    }

    get isCreatePermited() {
        return this.permissionCheck(...this.permissionNames);
    }

    get pickerLoading() {
        return this.picker.pickerLoading;
    }

    get pickerItems() {
        const pickerData = this.picker ? this._.cloneDeep(this.picker.pickerData) : [];
        if (this.selectedItem && this.selectedItem.id) {
            const items = pickerData;
            // @ts-ignore
            if (!pickerData.some((el) => el.id === this.selectedItem.id)) {
                items.unshift(this.selectedItem);
            }
            return items;
        }
        return pickerData;
    }

    get props() {
        return this.module.moduleProps;
    }

    get mutationsTypes() {
        return this.module.mutationsTypes;
    }

    get fetchParams() {
        return {
            pickerId: this.pickerId,
            params: {
                filters: {
                    ...(this.filters ? this.filters : null),
                    search: this.search,
                },
            },
        };
    }

    get meta() {
        return this.picker.pickerMeta;
    }

    get editedNewObject() {
        return this.state.editedItem;
    }

    set editedNewObject(value) {
        this.$store.commit(this.mutationsTypes.UPDATE_EDITED, value);
    }

    /** used to show v-autocomplete loader only on search and init,
     *  @member pickerLoading would show loader when fetching next pages
     */
    public searchLoading = false;
    public fetchAction!: string;
    public showAction!: string;
    public selectedItem: { [key: string]: any } | null = null;
    public isSelectedItemInitialized = false;
    public loadingSelectedItem = false;

    public timer: any;
    public search: string = ' ';
    public lastSearch: string | null = null;

    public newObjectVDialog: boolean = false;
    public isAutocompleteFocused: boolean = false;

    public pickerId!: string;

    public created() {
        this.pickerId = uniqueId();
        this.$store.commit(this.mutationsTypes.REGISTER_PICKER, this.pickerId);
        this.picker = this.state.pickers.find((el) => el.id === this.pickerId) as PickerData;

        this.fetchAction = this.module.actionsTypes.FETCH_PICKER;
        this.showAction = this.module.actionsTypes.SHOW_ITEM;

        this.$nextTick(() => {
            if ((this.meta && this.meta.current_page !== 1) || this.picker.pickerData.length === 0) {
                this.sendSearchRequest('', true, this.initializeSelectedItem);
            } else {
                this.initializeSelectedItem();
            }
        });
    }

    public destroyed() {
        this.$store.commit(this.mutationsTypes.UNREGISTER_PICKER, this.pickerId);
    }

    public onAutocompleteFocus() {
        this.isAutocompleteFocused = true;
    }
    public onAutocompleteBlur(event: FocusEvent) {
        this.isAutocompleteFocused = false;

        this.$nextTick(() => {
            // avoids reseting when opening formDialog
            if (this.createItemBtn && this.createItemBtn.$el.isEqualNode(event.relatedTarget as Node)) {
                return;
            }

            clearTimeout(this.timer);

            if (this.picker.pickerData.length === 0 || (this.search && this.search !== '')) {
                this.sendSearchRequest('', true);
            }
        });
    }

    public createItem() {
        this.editedNewObject = Object.assign({}, this.itemTemplate);
        this.newObjectVDialog = true;
    }

    public itemSaved({ data }: SaveResponse<any>) {
        if (data) {
            this.search = '';
            this.selectedItem = data;
        }
        (this.$refs.vAutoComplete as HTMLElement)?.blur();
        this.newObjectVDialog = false;
        this.$emit('add');
    }

    /** Fixes vuetify bug that sets selectedItem to null when user try to erase last character */
    public interuptDeletion(event: KeyboardEvent) {
        if (this.search.length === 1) {
            event.preventDefault();
            event.stopPropagation();
            this.search = '';
        }
    }

    @Watch('search')
    public onSearch(newVal: string, oldVal: string) {
        if (!this.isSelectedItemInitialized) {
            return;
        }

        if (
            newVal &&
            !(oldVal && newVal.trim() === oldVal.trim()) &&
            !(this.lastSearch !== null && this.lastSearch.trim() === newVal.trim())
        ) {
            clearTimeout(this.timer);
            this.sendSearchRequest(newVal, false);
        } else if (!newVal && !!this.lastSearch) {
            clearTimeout(this.timer);
            this.sendSearchRequest('', false);
        }
    }

    @Watch('selectedItem', { deep: true })
    public onSelectedItemChange(val: any) {
        this.assignValue(val);
        this.$emit('changed', val, val ? val.id : null);

        clearTimeout(this.timer);

        if (this.lastSearch !== '') {
            this.sendSearchRequest('', true);
        }
    }

    @Watch('$props.filters')
    public afterChangeFilters(value: any, oldValue: any) {
        if (!this._.isEqual(value, oldValue)) {
            this.sendSearchRequest('', true);
        }
    }

    @Watch('value')
    public onItemValueChange(newVal: any, oldVal: any) {
        if (!newVal) {
            this.selectedItem = newVal;
        } else if (newVal !== oldVal) {
            this.isSelectedItemInitialized = false;
            this.initializeSelectedItem();
        }
    }

    private initializeSelectedItem() {
        if (!this.itemValue) {
            this.isSelectedItemInitialized = true;
            return;
        }

        if (this.returnObject) {
            this.selectedItem = this.itemValue;
        } else if (this.pickerItems.some((el) => el.id === this.itemValue)) {
            this.selectedItem = this.pickerItems.find((el) => el.id === this.itemValue);
        } else {
            this.selectedItem = { [this.returnValueKey]: this.itemValue, autoComplete__showLoader: true };
            this.fetchSelectedItem(this.itemValue);
        }
        this.isSelectedItemInitialized = true;
    }

    private assignValue(val: any) {
        if (this.returnObject) {
            this.$set(this, 'itemValue', val);
        } else {
            this.$set(this, 'itemValue', val ? val[this.returnValueKey] : null);
        }
    }

    private sendSearchRequest(
        value: any,
        isNotWritten: boolean,
        cb = () => {
            /**/
        },
    ) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.searchLoading = true;
            this.$store
                .dispatch(this.fetchAction, {
                    pickerId: this.pickerId,
                    params: {
                        filters: {
                            ...(this.filters ? this.filters : null),
                            search: value,
                        },
                        is_extra_fetch: true,
                        page: 1,
                    },
                })
                .then(({ data }) => {
                    if (isNotWritten) {
                        this.search = value;
                    }
                    cb();
                })
                .catch((err) => {
                    logger.error(err);
                })
                .finally(() => {
                    this.searchLoading = false;
                    this.lastSearch = value;
                });
        }, 600);
    }

    private fetchSelectedItem(id: number | string) {
        this.loadingSelectedItem = true;

        // if (!this.showAction) {
        //     /**
        //      * showAction may not by initialized during execution due to immediate value watcher
        //     */
        //    this.$nextTick(()=>this.fetchSelectedItem(id))
        //    return;
        // }

        this.$store
            .dispatch(this.showAction, { id })
            .then(({ data }) => {
                this.selectedItem = data;
            })
            .catch((err) => {
                logger.error(err);
                this.itemValue = null;
            })
            .finally(() => {
                this.isSelectedItemInitialized = true;
                this.loadingSelectedItem = false;
            });
    }
}
